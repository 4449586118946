import React from "react";
import styled from "styled-components";
import ProjectBox from "./ProjectBox";
import { Link } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import { projects } from "../data";
import { useSelector } from "react-redux";

const breakPoint = "(max-width: 750px)";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
`

export const ProjectGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.25em 1em 3em 1em;
  gap: 25px;
  max-width: 1300px;

  @media ${breakPoint} {
    margin: 3rem 1em 0 1em;
  }
`;

const AllProjectsLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const AllProjects = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border: 3px solid var(--primaryColor);
  transition: all 0.3s ease-out;
  color: ${(props) => (props.darkMode ? "var(--darkModeText)" : "inherit")};
  font-size: 1.25rem;
  font-weight: 500;
  margin: 4em 0;

  > svg {
    width: 1.25em;
    height: 1.25em;
    margin-left: 0.5em;
  }

  :hover {
    color: white;
    background: var(--primaryColor);
  }
`;

export default function Projects() {
  let featuredProjects = projects.slice(0, 6);
  const darkMode = useSelector((state) => state.theme.value);

  return (
    <Container>
      <Title>Projects</Title>
      <ProjectGrid>
        {featuredProjects.map((data, index) => (
          <ProjectBox
            ImgSrc={data.ImgSrc}
            title={data.title}
            desc={data.desc}
            liveLink={data.liveLink}
            github={data.github}
            key={index}
          />
        ))}
      </ProjectGrid>
      {/* <AllProjectsLink to="/AllProjects">
        <AllProjects darkMode={darkMode}>
          See All Projects <FaArrowCircleRight />
        </AllProjects>
      </AllProjectsLink> */}
    </Container>
  );
}

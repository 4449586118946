import React from "react";
import styled from "styled-components";
import TechBox from "./TechBox";
import { skills } from "../data";
import { useSelector } from "react-redux";

const breakPoint = "(max-width: 800px)";

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: var(--width);
  max-width: 1200px;
  margin: 0 auto;
  color: ${(props) => (props.darkMode ? "var(--darkModeText)" : "black")};

  @media ${breakPoint} {
    flex-direction: column;
    margin-top: 1em;
    padding-bottom: 2em;
  }
`;

const TechContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
  transition: all 0.3s ease;

  @media ${breakPoint} {
    margin: 0 0 1.5em 0;
  }
`;

const TechTitle = styled.h2`
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 2.5rem;
`;

const TechWrapper = styled.div`
  max-width: 680px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

export default function Skills() {
  const darkMode = useSelector((state) => state.theme.value);

  return (
    <Container darkMode={darkMode}>
      <TechContainerBox>
        <TechTitle>Skills</TechTitle>
        <TechWrapper>
          {skills.map((data, index) => (
            <TechBox src={data.src} name={data.name} key={index} />
          ))}
        </TechWrapper>
      </TechContainerBox>
    </Container>
  );
}

import React from 'react'
import styled from 'styled-components'
import Intro from './Intro'
import Skills from './Skills'
import Projects from './Projects'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 96px);
  gap: 4em;
  padding: 3em 0 1em 0;
`

export default function HomePage() {
  return (
    <Container>
      <Intro />
      <Skills />
      <Projects />
    </Container>
  )
}

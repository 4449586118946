export const projects = [
  {
    ImgSrc: "Images/ProjectGrid/scoreboard.png",
    title: "Live Scoreboard",
    desc: "Get the scores of various sports leagues in real time. The data is fetched using ESPN's REST API. Made with the NextJS React framework",
    liveLink: "https://mm-livescores.vercel.app/",
    github: "https://github.com/MichaelMcCann1/LiveScoreboardV2",
  },
  {
    ImgSrc: "Images/ProjectGrid/LitLog.png",
    title: "LitLog",
    desc: "Track your reading by searching for books and adding them to your bookshelves. Made with NextJS and Postgres database.",
    liveLink: "https://litlog.vercel.app/",
    github: "https://github.com/MichaelMcCann1/LitLog",
  },
  {
    ImgSrc: "Images/ProjectGrid/flappybird.png",
    title: "Flappy Bird",
    desc: "Flappy Bird clone made with vanilla JavaScript. Press space or tap the screen to jump. Navigate your way through the obstacles to earn points.",
    liveLink: "https://mm-flappybird.web.app/",
    github: "https://github.com/MichaelMcCann1/Flappy-Bird",
  },
  {
    ImgSrc: "Images/ProjectGrid/hangman.png",
    title: "Hangman",
    desc: "The classic game of hangman made with ReactJS. Guess the correct letters to solve the puzzle before you lose!",
    liveLink: "https://mm-hangman-react.web.app/",
    github: "https://github.com/MichaelMcCann1/Hangman-React",
  },
  {
    ImgSrc: "Images/ProjectGrid/imageGallery.png",
    title: "Photo Gallery Animation",
    desc: "An image gallery with a transition animation between images. Made with ReactJS and has a responsive design.",
    liveLink: "https://mm-imagegalleryanimation.web.app/",
    github: "https://github.com/MichaelMcCann1/Image-Gallery-Animation",
  },
  {
    ImgSrc: "Images/ProjectGrid/typingtest.png",
    title: "Speed Typing Test",
    desc: "A speed typing test made with vanilla JavaScript. The test calculates your Words Per Minute and your accuracy.",
    liveLink: "https://mm-typingtest.web.app/",
    github: "https://github.com/MichaelMcCann1/Typing-Test",
  },
  {
    ImgSrc: "Images/ProjectGrid/clock.png",
    title: "Digital Clock",
    desc: "Segmented display digital clock made with ReactJS. The line segments on each digit turn red to create the appearance of a number.",
    liveLink: "https://mm-digitalclock-react.web.app/",
    github: "https://github.com/MichaelMcCann1/DigitalClock-React",
  },
  {
    ImgSrc: "Images/ProjectGrid/rockpaperscissors.png",
    title: "RockPaperScissors - React",
    desc: "Rock Paper Scissors game made with ReactJS. Play against the computer and the game will keep track of the scores.",
    liveLink: "https://mm-rockpaperscissors-react.web.app/",
    github: "https://github.com/MichaelMcCann1/Rock-Paper-Scissors-React",
  },
  {
    ImgSrc: "Images/ProjectGrid/hangman.png",
    title: "Hangman - vanilla JS",
    desc: "The classic game of hangman made with vanilla JavaScript. Guess the correct letters to solve the puzzle before you lose!",
    liveLink: "https://mm-hangman.web.app/",
    github: "https://github.com/MichaelMcCann1/Hangman",
  },
  {
    ImgSrc: "Images/ProjectGrid/clock.png",
    title: "Digital Clock - vanilla JS",
    desc: "Digital clock made with vanilla JavaScript. The line segments on each digit turn red to create the appearance of a number.",
    liveLink: "https://mm-digitalclock.web.app/",
    github: "https://github.com/MichaelMcCann1/DigitalClock",
  },
  {
    ImgSrc: "Images/ProjectGrid/rockpaperscissors.png",
    title: "Rock Paper Scissors",
    desc: "Rock Paper Scissors game made with vanilla JavaScript. Play against the computer and the game will keep track of the scores.",
    liveLink: "https://mm-rockpaperscissors.web.app/",
    github: "https://github.com/MichaelMcCann1/Rock-Paper-Scissors",
  },
];

export const skills = [
  {
    src: "Images/Tech/HTML.svg",
    name: "HTML",
  },
  {
    src: "Images/Tech/CSS.svg",
    name: "CSS",
  },
  {
    src: "Images/Tech/typescript.svg",
    name: "TypeScript",
  },
  {
    src: "Images/Tech/React.svg",
    name: "React",
  },
  {
    src: "Images/Tech/NextJS.svg",
    name: "Next.JS",
  },
  {
    src: "Images/Tech/Tailwind.svg",
    name: "Tailwind",
  },
  {
    src: "Images/Tech/Jest.svg",
    name: "Jest",
  },
  {
    src: "Images/Tech/Git.svg",
    name: "Git",
  },
  {
    src: "Images/Tech/postgreSQL.svg",
    name: "PostgreSQL",
  },
  {
    src: "Images/Tech/Recoil.png",
    name: "Recoil",
  },
  {
    src: "Images/Tech/MUI.svg",
    name: "Material UI",
  },
  {
    src: "Images/Tech/ReactQuery.svg",
    name: "React Query",
  },
  {
    src: "Images/Tech/chartjs.svg",
    name: "Chart.js",
  },
  {
    src: "Images/Tech/Redux.svg",
    name: "Redux",
  },
  {
    src: "Images/Tech/zod.svg",
    name: "Zod",
  },
  {
    src: "Images/Tech/playwright.svg",
    name: "Playwright",
  },
];

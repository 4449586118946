import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import GlobalStyle from "./components/GlobalStyle";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import themeReducer from "./features/themeSlice";
import HomePage from "./components/HomePage";

const store = configureStore({
  reducer: {
    theme: themeReducer,
  },
});

function App() {
  return (
    <Router>
      <Provider store={store}>
        <ScrollToTop />
        <GlobalStyle />
        <Header />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          {/* <Route exact path="/AllProjects">
            <AllProjects />
          </Route> */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
